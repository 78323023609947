import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Policy from '../views/Policy.vue'


// import securityRoute from './security.js'
// import adminRoute from './administration.js'
// import saleRoute from './sale.js'
// import accountingRoute from './accounting.js'
// import flowRoute from './flow.js'

Vue.use(VueRouter)

const mainRoutes = [
    {
        path: '*',
        name: 'home',
        component: Home
    },
    {
      path: '/PolitiqueDeConfidentialiter',
      name: 'Policy',
      component: Policy
    }    
]

const routes = mainRoutes.concat(
    // securityRoute, 
    // adminRoute,
    // saleRoute,
//    accountingRoute,
//    flowRoute
    );


const router = new VueRouter({
    routes
})

export default router
