<template>
    <div>
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2773.1615070874295!2d-72.68581802200828!3d45.96803362456135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc871e2d143beb9%3A0x69a33ccc41754980!2s796%20QC-143%2C%20Saint-Bonaventure%2C%20QC%20J0C%201C0!5e0!3m2!1sfr!2sca!4v1726498289750!5m2!1sfr!2sca" width="100%" style="border:0; height: 300px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="footer md-layout" >
            <img class="" style="margin: 0px; padding: 0px; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);" src="/resources/images/bg-footer.jpg" alt="background aluminium footer" title="background aluminium footer"/>
            <div class="md-layout-item md-layout grid-pad colorFooter">
                
                <div class="md-layout-item md-size-100 md-layout md-alignment-center" style="min-height: 250px;">
                    <div class="md-layout-item pt-10 md-layout elementHeight" >
                        <div class="md-layout-item md-layout" style="min-width: 250px; max-width: 400px; height: inherit; margin: auto;">
                            <div class="md-layout-item md-size-100 txt-c"><h2 >Nous joindre</h2></div>
                            <div class="md-layout-item pt-10 md-size-100 md-layout">
                                <div class="md-layout-item md-layout">
                                    <div class="md-layout-item" style="text-align: left;"><p >Courriel :</p></div>
                                    <div class="md-layout-item" style="text-align: right;"><a class="f-tierce" href="mailto:info@quintech.qc.ca">info@quintech.qc.ca</a></div>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-100"> 
                                <div class="md-layout-item md-layout">
                                    <div class="md-layout-item" style="text-align: left;"><p>Téléphone :</p></div>
                                    <div class="md-layout-item" style="text-align: right;"><a class="phone" href="tel:18193965555">(819) 396-5555</a></div>
                                </div>
                            </div>
                            <div class="md-layout-item pt-5 md-size-100"><div><div style="text-align: left;"><a class="f-tierce" @click="openCookie()">Mes témoins</a></div></div></div>
                            <div class="md-layout-item md-size-100"><div><div style="text-align: left;"><a class="f-tierce" @click="navigateToPolicy()">Politique de confidentialité</a></div></div></div>
                        </div>
                        
                    </div>
                    <div class="md-layout-item pt-10 md-layout elementHeight">
                        <div class="md-layout-item md-layout" style="min-width: 250px; max-width: 400px; height: inherit; margin: auto;">
                            <div class="md-layout-item md-size-100 txt-c"><h2>Adresse</h2></div>
                            <div class="md-layout-item pt-10 md-size-100" ><div><div><p class="f-tierce" >796, route 143</p></div></div></div>
                            <div class="md-layout-item md-size-100" ><div><div ><p class="f-tierce">St-Bonaventure (Québec)</p></div></div></div>
                            <div class="md-layout-item md-size-100" ><div><div ><p class="f-tierce">J0C 1C0</p></div></div></div>
                        </div>
                        
                    </div>
                    <div class="md-layout-item pt-10 md-layout elementHeight">
                        <div class="md-layout-item md-layout" style="min-width: 250px; max-width: 400px; height: inherit; margin: auto;">
                            <div class="md-layout-item md-size-100 txt-c"><h2>HEURES D’OUVERTURE</h2></div>
                            <div class="md-layout-item pt-10 md-size-100">
                                <div class="md-layout-item md-layout">
                                    <div class="md-layout-item" style="text-align: left;"><p class="f-tierce">Lundi au jeudi :</p></div>
                                    <div class="md-layout-item" style="text-align: right;"><p class="f-tierce">8h à 17h</p></div>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-100"> 
                                <div class="md-layout-item md-layout">
                                    <div class="md-layout-item" style="text-align: left;"><p class="f-tierce">vendredi :</p></div>
                                    <div class="md-layout-item" style="text-align: right;"><p class="f-tierce">8h à 12h</p></div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
                <div class="md-layout-item md-size-100 md-layout">
                    <div class="md-layout-item md-layout md-alignment-center-left"><p class="subTitle">Quintech…une porte ouverte sur l'avenir&nbsp;!</p>
                    <span class="copyright">&#169;2024.11.23.0 - Tous droits réservés | Quintech</span>
                    </div>
                    <div style="max-width: 100px;" class="md-layout-item">
                        <a href="https://www.facebook.com/pages/Quintech-Quincaillerie/148353405594399" target="_Blank">
                            <img style="width: 50px; height: auto; padding: 5px;" src="/resources/images/facebook.png" alt="logo facebook" title="logo facebook" />
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<style scoped>
    .f-tierce{ color: rgb(208, 208, 208); font-weight: 700; }
    .footer { position: relative; min-height: 300px; width: 100%; overflow: hidden; margin-top: -8px;  }
    .colorFooter { background-color: rgba(40, 133, 34,0.9); position: relative; z-index: 10; padding-top: 10px; }
    .subTitle { color: rgb(208, 208, 208); font-size: clamp(1.5em,1vw,2em); text-transform: uppercase; text-align: left; font-weight: 700; height: 20px; }
    a:hover { text-decoration: underline; cursor: pointer; }
    .v-col {padding: 5px 10px;}
    h2 { font-size: clamp(1.5em,1.25vw,2em); color: rgb(10, 10, 10); text-transform: uppercase; font-weight: 700;}
    p { color: white; font-size: clamp(0.75em,1.25vw,1em); }
    .phone { font-weight: 700; color: white !important;}
    .pt-10 { padding-top: 20px; }
    .elementHeight { height: 150px; padding: 5px; }
    .copyright { margin-left: 15px; margin-top: 15px; color: rgb(208, 208, 208); }
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
export default {
    name: 'Footer',
    data: () => ({
    }),
    mounted() {
    },
    methods: {
        navigateToPolicy: function(){ this.$router.push('/PolitiqueDeConfidentialiter'); },
        openCookie: function(){ $App.event.$emit('openCookie'); }
    },
    component: {}
}
</script>