var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"footer md-layout"},[_c('img',{staticStyle:{"margin":"0px","padding":"0px","position":"absolute","top":"50%","left":"50%","transform":"translate(-50%,-50%)"},attrs:{"src":"/resources/images/bg-footer.jpg","alt":"background aluminium footer","title":"background aluminium footer"}}),_c('div',{staticClass:"md-layout-item md-layout grid-pad colorFooter"},[_c('div',{staticClass:"md-layout-item md-size-100 md-layout md-alignment-center",staticStyle:{"min-height":"250px"}},[_c('div',{staticClass:"md-layout-item pt-10 md-layout elementHeight"},[_c('div',{staticClass:"md-layout-item md-layout",staticStyle:{"min-width":"250px","max-width":"400px","height":"inherit","margin":"auto"}},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"md-layout-item pt-5 md-size-100"},[_c('div',[_c('div',{staticStyle:{"text-align":"left"}},[_c('a',{staticClass:"f-tierce",on:{"click":function($event){return _vm.openCookie()}}},[_vm._v("Mes témoins")])])])]),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('div',[_c('div',{staticStyle:{"text-align":"left"}},[_c('a',{staticClass:"f-tierce",on:{"click":function($event){return _vm.navigateToPolicy()}}},[_vm._v("Politique de confidentialité")])])])])])]),_vm._m(4),_vm._m(5)]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('iframe',{staticStyle:{"border":"0","height":"300px"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2773.1615070874295!2d-72.68581802200828!3d45.96803362456135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc871e2d143beb9%3A0x69a33ccc41754980!2s796%20QC-143%2C%20Saint-Bonaventure%2C%20QC%20J0C%201C0!5e0!3m2!1sfr!2sca!4v1726498289750!5m2!1sfr!2sca","width":"100%","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item md-size-100 txt-c"},[_c('h2',[_vm._v("Nous joindre")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item pt-10 md-size-100 md-layout"},[_c('div',{staticClass:"md-layout-item md-layout"},[_c('div',{staticClass:"md-layout-item",staticStyle:{"text-align":"left"}},[_c('p',[_vm._v("Courriel :")])]),_c('div',{staticClass:"md-layout-item",staticStyle:{"text-align":"right"}},[_c('a',{staticClass:"f-tierce",attrs:{"href":"mailto:info@quintech.qc.ca"}},[_vm._v("info@quintech.qc.ca")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item md-size-100"},[_c('div',{staticClass:"md-layout-item md-layout"},[_c('div',{staticClass:"md-layout-item",staticStyle:{"text-align":"left"}},[_c('p',[_vm._v("Téléphone :")])]),_c('div',{staticClass:"md-layout-item",staticStyle:{"text-align":"right"}},[_c('a',{staticClass:"phone",attrs:{"href":"tel:18193965555"}},[_vm._v("(819) 396-5555")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item pt-10 md-layout elementHeight"},[_c('div',{staticClass:"md-layout-item md-layout",staticStyle:{"min-width":"250px","max-width":"400px","height":"inherit","margin":"auto"}},[_c('div',{staticClass:"md-layout-item md-size-100 txt-c"},[_c('h2',[_vm._v("Adresse")])]),_c('div',{staticClass:"md-layout-item pt-10 md-size-100"},[_c('div',[_c('div',[_c('p',{staticClass:"f-tierce"},[_vm._v("796, route 143")])])])]),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('div',[_c('div',[_c('p',{staticClass:"f-tierce"},[_vm._v("St-Bonaventure (Québec)")])])])]),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('div',[_c('div',[_c('p',{staticClass:"f-tierce"},[_vm._v("J0C 1C0")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item pt-10 md-layout elementHeight"},[_c('div',{staticClass:"md-layout-item md-layout",staticStyle:{"min-width":"250px","max-width":"400px","height":"inherit","margin":"auto"}},[_c('div',{staticClass:"md-layout-item md-size-100 txt-c"},[_c('h2',[_vm._v("HEURES D’OUVERTURE")])]),_c('div',{staticClass:"md-layout-item pt-10 md-size-100"},[_c('div',{staticClass:"md-layout-item md-layout"},[_c('div',{staticClass:"md-layout-item",staticStyle:{"text-align":"left"}},[_c('p',{staticClass:"f-tierce"},[_vm._v("Lundi au jeudi :")])]),_c('div',{staticClass:"md-layout-item",staticStyle:{"text-align":"right"}},[_c('p',{staticClass:"f-tierce"},[_vm._v("8h à 17h")])])])]),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('div',{staticClass:"md-layout-item md-layout"},[_c('div',{staticClass:"md-layout-item",staticStyle:{"text-align":"left"}},[_c('p',{staticClass:"f-tierce"},[_vm._v("vendredi :")])]),_c('div',{staticClass:"md-layout-item",staticStyle:{"text-align":"right"}},[_c('p',{staticClass:"f-tierce"},[_vm._v("8h à 12h")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item md-size-100 md-layout"},[_c('div',{staticClass:"md-layout-item md-layout md-alignment-center-left"},[_c('p',{staticClass:"subTitle"},[_vm._v("Quintech…une porte ouverte sur l'avenir !")]),_c('span',{staticClass:"copyright"},[_vm._v("©2024.11.23.0 - Tous droits réservés | Quintech")])]),_c('div',{staticClass:"md-layout-item",staticStyle:{"max-width":"100px"}},[_c('a',{attrs:{"href":"https://www.facebook.com/pages/Quintech-Quincaillerie/148353405594399","target":"_Blank"}},[_c('img',{staticStyle:{"width":"50px","height":"auto","padding":"5px"},attrs:{"src":"/resources/images/facebook.png","alt":"logo facebook","title":"logo facebook"}})])])])
}]

export { render, staticRenderFns }