<template>
  <div id="app">
    <Cookie />
    <cHeader />
    <div>
      <router-view />
    </div>
    <cFooter />
  </div>
</template>
<script>
    import cHeader from './components/content-header.vue'
    import cFooter from './components/content-footer.vue'  
    import Cookie from './components/cookie.vue';
    export default {
      name: 'App',
      data: () => ({
        authorizations:{
            Essential: true,
            Performance: false,
            Personalization: false,
            Advertising: false,
        },
      }),
      mounted: async function () {},
      methods:{},
      components: {
        Cookie,
        "cHeader": cHeader,
        "cFooter": cFooter
      }
  
    }
  </script>