<template>
  <div class="bgHomePage md-layout" >
    <!--section2 text top-->
    <section class="md-layout-item positionTopContainer md-size-100 md-layout md-alignment-center">
      <div class="md-layout-item  grid-pad md-layout topContainer" style=" ">
        <!-- left side-->
        <div class="md-layout-item md-size-50 md-small-size-100  md-layout md-alignment-center">
            <div class="md-layout-item section2Text">
                <h2 class="f-primary">Bienvenue chez Quintech</h2>
                <p class="f-white">
                    Spécialise en quincaillerie architecturale, cadres, portes d'acier et portes de bois.</br> 
                    <span class="fw-700">Commercial, industriel, institutionnel, carcéral, minier et multi résidentiel.</span>
                </p>
            </div>
        </div>
        <!--right side-->
        <div class="md-layout-item md-size-50 md-small-size-100  md-layout md-alignment-center">
            <div class="md-layout-item section2Text">
                <p class="f-primary"></p>
                <p class="f-white">Quintech Quincaillerie se spécialise en quincaillerie architecturale ainsi que dans la vente de cadres, de portes d’acier et de de portes de bois. Il œuvre dans les domaines commercial, industriel, institutionnel, carcéral, minier et multi-résidentiel.</p>
                <p class="f-white">Avec son usine de fabrication, Quintech Quincaillerie a la possibilité d’offrir des cadres et des portes d’acier dans un délai de livraison hors pair, sans compter son large inventaire de quincaillerie architecturale.</p>
            </div>
        </div>
      </div>
    </section>
    <section class="md-layout" id="webCarousel">
        <div class="md-layout-item md-size-75  md-layout md-alignment-center-center Caroussel"> <!--POsition relative for z-index to check in final production FXGL**-->
            <Carousel class="md-layout-item md-size-100 md-layout "  :perPage='1' :centerMode="true" :autoplay="true" :autoplayTimeout="5000" :speed="3000" :loop="true" :infinite="true" :paginationEnabled="true" :mouse-drag="false" :zIndex="0">
                <slide class="md-layout-item md-size-100 md-layout diapoHeight">
                    <div class="md-layout-item md-layout md-alignment-center-center bgSlide">
                        <span style="text-align: center;">
                            <h2 class="diapoTitle">Réalisation minier</h2><br>
                            <p class="diapoSubtitle">Aménagement du Canadian Malartic Osisko , Malartic </p>
                        </span>
                        
                    </div>
                    <div class="md-layout-item md-small-size-100">
                        <img  class="imgWidth" src="../../../public/resources/images/realisationMiniere.jpg" title="" alt="" />
                    </div>          
                </slide>
                <slide class="md-layout-item md-size-100 md-layout diapoHeight">
                    <div class="md-layout-item md-layout md-alignment-center-center bgSlide">
                        <span style="text-align: center;">
                            <h2 class="diapoTitle">Réalisation CV&#x2011;Tech</h2><br>
                            <p class="diapoSubtitle">Aménagement du CV&#x2011;Tech </p>
                        </span>
                    </div>
                    <div class="md-layout-item md-small-size-100">
                        <img class="imgWidth"  src="../../../public/resources/images/realisationCvtech.jpg" title="" alt="" />
                    </div>   
                </slide>
                <slide class="md-layout-item md-size-100 md-layout diapoHeight">
                    <div class="md-layout-item md-layout md-alignment-center-center bgSlide">
                        <span style="text-align: center;">
                            <h2 class="diapoTitle">Réalisation Fromagerie Saint&#x2011;Guillaume</h2><br>
                            <p class="diapoSubtitle">Aménagement du Réalisation Fromagerie Saint&#x2011;Guillaume </p>
                        </span>
                    </div>
                    <div class="md-layout-item md-small-size-100">
                        <img class="imgWidth" src="../../../public/resources/images/realisationFromagerie.jpg" title="" alt="" />
                    </div> 
                </slide>
                <slide class="md-layout-item md-size-100 md-layout diapoHeight">
                    <div class="md-layout-item md-layout md-alignment-center-center bgSlide">
                        <span style="text-align: center;">
                            <h2 class="diapoTitle">Réalisation SADP</h2><br>
                            <p class="diapoSubtitle">Aménagement du Réalisation SADP</p>
                        </span>
                    </div>
                    <div class="md-layout-item md-small-size-100">
                        <img class="imgWidth" src="../../../public/resources/images/realisationSadp.jpg" title="" alt="" />
                    </div> 
                </slide>
            </Carousel>
        </div>
    </section>
    <section class="md-layout-item md-size-100 md-layout " style="margin-bottom: 100px;">
        <div class="md-layout-item md-size-100 bgForm gridpadForm md-layout" style="position: relative; height: 100%; padding-top:30px !important; background-color: rgba(10,10,10,0.8); border-top: 10px solid rgb(40, 133, 34); border-bottom: 10px solid rgb(40, 133, 34);">
            <div class="md-layout-item  md-size-100" >
                <div><h2 class="f-white txt-c" style="text-transform: uppercase;">Nous Joindre</h2></div>
            </div>
            <div class="md-layout-item md-size-100 md-layout" style="padding-top: 50px;">
                <div class="md-layout-item md-size-100 md-layout">
                    <form class="md-layout-item md-size-100 md-layout" ref="form">
                    <!--Form-->
                        <div class="md-layout-item md-size-100 md-layout">
                            <div class="md-layout-item md-size-100 md-layout">
                                
                                <div class="md-layout-item md-size-100 md-layout">
                                    <!--name-->
                                    <div class="md-layout-item md-size-50 md-small-size-100 md-layout fieldMin">
                                        <div class="md-layout-item md-size-100 padForm md-layout">
                                            <p :class="{'error': error.name == true }" class="bold f-white" style="font-size: 14px;" >Nom:</p>
                                        </div>
                                        <div class="md-layout-item md-size-100 padForm md-layout">
                                            <md-field  style="background-color: rgba(40, 133, 34,0.8);">
                                                <label>Nom</label>
                                                <md-input id="name" name="name" type="text" @change="error.name = false" v-model="item.name" clearable required></md-input> 
                                            </md-field>
                                        </div>
                                    </div>
                                    <!--city-->
                                    <div class="md-layout-item md-size-50 md-small-size-100 md-layout fieldMin">
                                        <div class="md-layout-item md-size-100 padForm md-layout"><p class="bold f-white" style="font-size: 14px;">Adresse</p></div>
                                        <div class="md-layout-item md-size-100 padForm md-layout">
                                            <md-field  style="background-color: rgba(40, 133, 34,0.8);" >
                                                <label>Adresse</label>
                                                <md-input id="Ville" name="Ville" type="text" v-model="item.location" clearable required></md-input> 
                                            </md-field>
                                        </div>
                                    </div>
                                    <!--email-->
                                    <div class="md-layout-item md-size-50 md-small-size-100 md-layout fieldMin">
                                        <div class="md-layout-item md-size-100 padForm md-layout">
                                            <p :class="{'error': error.email == true }" class="bold f-white" style="font-size: 14px;" >Courriel:</p>
                                        </div>
                                        
                                        <div class="md-layout-item md-size-100 padForm md-layout">
                                            <md-field  style="background-color: rgba(40, 133, 34,0.8);" >
                                                <label>Courriel</label>
                                                <md-input id="Courriel" name="Courriel" type="text" v-model="item.email" clearable required></md-input> 
                                            </md-field>
                                        </div>
                                    </div>
                                    <!--phone-->
                                    <div class="md-layout-item md-size-50 md-small-size-100 md-layout fieldMin">
                                        <div class="md-layout-item md-size-100 padForm md-layout">
                                            <p :class="{'error': error.phone == true }" class="bold f-white" style="font-size: 14px;" >Téléphone:</p>
                                        </div>
                                        <div class="md-layout-item md-size-100 padForm md-layout">
                                            <md-field  style="background-color: rgba(40, 133, 34,0.8);" >
                                                <label>xxx xxx-xxxx</label>
                                                <md-input id="Telephone" name="Telephone" type="text" v-model="item.phone" clearable required></md-input> 
                                            </md-field>
                                            <!--<p class="errorText" v-if="error.phone" style="font-size: 14px; margin-top: -25px;">Un téléphone est requis **</p>-->
                                        </div>
                                    </div>
                                    <!--Description-->
                                    <div class="md-layout-item md-size-100 md-layout fieldFormMax">
                                        <div class="md-layout-item md-size-100 padForm md-layout">
                                            <p :class="{'error': error.message == true }" class="bold f-white" style="font-size: 14px;" >Description du projet:</p>
                                        </div>
                                        <div class="md-layout-item md-size-100 padForm md-layout">
                                            <md-field style="background-color: rgba(40, 133, 34,0.8);">
                                                <label>Description</label>
                                                <md-textarea id="description" name="description" v-model="item.message" clearable required></md-textarea>
                                            </md-field>
                                            <!--<v-textarea  no-resize  label=""  bg-color="green-lighten-4" rows="7" row-height="45" single-line :counter="520" maxlength="520"/>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--accept form-->
                        <div class="md-layout-item md-size-100 md-layout">
                            <div class="md-layout-item md-size-100 md-large-size-50 md-layout">
                                <p v-if="this.Dialog == 'error'" class="" style="color: red; margin-left:15px;" >Il y a eu un probleme dans la demande veuillez recommencer</p>
                            </div>
                            <div class="md-layout-item md-size-100 md-large-size-50 md-layout md-alignment-center-right">
                                <span v-if="this.Dialog != 'send'" @click="SendForm()" class="button" style="margin-right:15px;">
                                    <p class="buttonText2" v-if="this.Dialog == 'notSend' || this.Dialog == 'error'" >Envoyer</p>
                                    <p class="buttonText2" v-if="this.Dialog == 'pending'">veuillez patientez pour l'envoie du formulaire</p>
                                </span>
                                <h3 id="ContactFormSent" v-if="this.Dialog == 'send'" class="f-primary" style="text-align: center; font-size: 2em;">La demande à été envoyer !</h3>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </section>
  </div>
</template>
<style>
        #webCarousel .VueCarousel-dot:focus { outline: 2px solid rgb(40, 133, 34) !important; background-color: rgb(40, 133, 34) !important; }
        #webCarousel .VueCarousel-dot--active {outline: 2px solid rgb(40, 133, 34) !important; }
        #webCarousel .VueCarousel-dot { background-color: rgb(40, 133, 34) !important; width: 15px !important; height: 15px !important;} 
</style>
<style scoped>
    .f-primary { color: rgb(40, 133, 34) !important; }
    .f-white {  color: white;}
    .bgHomePage { height: 100%; background-image: url('../../../public/resources/images/BannerIndexTop.jpg'); background-position: center center; background-size: cover; background-repeat: no-repeat; }
    h2 { font-weight: 700; }
    .section2Text p { font-size: clamp(0.75em,1.5vw,1.25em); line-height:29.26px; text-align: justify; margin: 25px 0px; font-weight: 100; }
    .positionTopContainer { position:relative; z-index:1;  top:0px; height: 600px;  }
    .topContainer { max-height: 300px; background-color: rgba(10, 10, 10,0.8);border-top: 10px solid rgb(40, 133, 34); border-bottom: 10px solid rgb(40, 133, 34); }
    .error { color: red !important; }
    /*#region caroussel*/
        .diapoTitle { color: rgb(40, 133, 34); }
        .diapoSubtitle { color: rgb(10,10,10); font-weight: 700; }
        .bgSlide { background-color: rgba(208,208,208,0.9); }
        .diapoHeight { height: 350px; }
        .Caroussel { height: 400px; margin: 0px auto 200px auto !important; }
        .imgWidth { width: auto !important; height: 100% !important; object-fit: cover; }

    /*#endregion*/ 
    /*#region Form*/
        .gridpadForm {padding: 0px 200px; }
        .bgForm {background-color: rgb(var(--tierce2));}
        .fieldMin { min-width: 50%; }
        .fieldFormMax { min-width: 100%; }
        .padForm { padding: 5px 10px;}
        .button {background: rgba(40, 133, 34,0.8) !important; border-radius: 10px; border:none; color:white ; padding:10px 50px;text-align:center;text-decoration:none;display:inline-block;font-size:16px;margin:4px 2px;cursor:pointer;}
        .button:hover {background: rgb(40, 133, 34)!important;}
        .buttonText2 {text-transform: uppercase; font-size:14px !important; color: rgb(255,255,255) !important; font-weight: 700;}
    /*#endregion*/ 
    @media handheld, only screen and (max-width: 600px) {
        .gridpadForm {padding: 50px 10px;}
    }
    @media handheld, only screen and (max-width: 960px) {
        .positionTopContainer { top:50px; }
        .Caroussel { height: unset; margin: 20px auto !important; overflow: hidden; }
        .BGTextCarousselContainer { margin: auto;}
        .imgContainer { margin: auto; }
        .topContainer { max-height: unset; }
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
    import { Carousel, Slide } from 'vue-carousel';
export default {
    name: 'Home',
    data: () => ({
        drawer: false,
        item: {
            name: null,
            email: null,
            phone: null,
            address: null,
            message: null
        },
        Dialog: "notSend", //"error, send, notSend"
        error:{
            name: false,
            phone: false,
            email: false,
            message: false
        },
    }),
    mounted() {
    },
    methods: {
        login: async function () {

            let api = 'https://abstracxion.wx3data.com/security/login/WX3'; // need Tenant BissonService 
            let data = null;
            try {
                let response = await fetch(api, {
                    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *client
                    body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
                })
                if (await response.ok) {
                    if (await response.status !== 204) {
                        let data = await response.text();
                        return data
                    }
                } else {
                    let msg = await response.text();
                    $App.event.$emit("app_error", msg);
                };
                    
            } catch (error) {
                console.log(error);
            }
        },
        SendForm: async function (){
            this.Dialog = "pending";
            let valid = await this.validation();
            if(valid == true){
            let formData = new FormData(this.$refs.form);
            let api = 'https://abstracxion.wx3data.com/eCommerce/Forms/ContactUs/sendByEmail';
            try {
                let token = await this.login();

                // ** ATTENTION need to add bearer token...
                let response = await fetch(api, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *client
                    body: formData
                });

                if (await response.ok) {
                    if (await response.status !== 204) {
                        this.Dialog = "send";
                    }
                } else {
                    this.Dialog = "error";
                };

            } catch (error) {

            }
            }else { this.Dialog = 'error'; }

        },
        validation: async function(){
            let valid = true;
            let errorFlag = {name: false, phone: false, email:false, message:false };
            if(this.item.name == null || this.item.name.length == 0){ valid = false; errorFlag.name = true; }
            if(this.item.phone == null || this.item.phone.length < 12){valid = false; errorFlag.phone = true; }
            if(this.item.email == null){valid = false; errorFlag.email = true; }
            if(this.item.message == null){valid = false; errorFlag.message = true; }
            this.error = errorFlag;
            return valid;
        },
        
    },
    components: { Carousel, Slide},
}
</script>