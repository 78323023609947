<template>
    <div>
        <div class="header">
            <div  class="md-layout grid-pad">
                <v-col class="md-layout-item logoWidth">
                    <router-link to="/app.html"><img class="logo" style="" src="/resources/images/logo.png" alt="logo de Quintech" title="logo de Quintech"></router-link>
                </v-col>
                <v-col class=" md-layout-item md-layout md-small-hide md-alignment-bottom-left"><p>une porte ouverte sur l'avenir&nbsp;!</p></v-col>
                <v-col class="md-layout-item md-layout" >
                    <h2 style="text-align: right; " class="md-layout-item md-size-100 md-layout md-alignment-center-right" ><a style="color: white;" class="md-layout-item md-alignment-center" href="/app.html">Accès client</a></h2>
                    <p style="text-align: right;" class="md-layout-item md-size-100" ><a style="color: white;" class="md-layout-item md-alignment-center" href="tel:18193965555">(819) 396-5555</a></p>
                </v-col>
            </div>
        </div>
    </div>
</template>
<style scoped>
    h2 { text-transform: uppercase; }
    a { color: white; font-size: clamp(0.75em,1.5vw,1em); }
    a:hover { color: rgb(var(--primary)); }
    p {  color: white; padding-left: 20px; height: 20px; }
    .logoWidth { max-width: 230px; }
    .header { padding: 0 0 15px 0; }

    @media handheld, only screen and (max-width: 600px) { 
        .logoWidth { max-width: 150px; }
        .logo { width: 100%; height: auto; margin: auto; }
    }
    @media handheld, only screen and (max-width: 960px) {
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
// import router from '@/router';

export default {
    name: 'Header',
    data: () => ({
        drawer: false,
    }),
    mounted() {
    },
    methods: {
    },
    component: {}
}
</script>